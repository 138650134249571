import React from "react"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import Meta from "../components/meta"
import InquiryWidget from "../components/inquiryWidget"
import BrandOverview from "../components/brandOverview"
import Footer from "../components/footer"
import formattedDate from "../functions/dateFormat"
import Footnote from "../components/footnote"
import BodyText from "../components/bodyText"
import "../styles/templates/_pressArticle.scss"

export default class ArticleTemplate extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll)
  }

  render() {
    const pressData = this.props.pageContext.press
    const headerData = this.props.pageContext.header[0]
    let coBrandClass = "nobrand"
    let coBrand = ""
    let coBrandOverview = ""
    if (pressData.coBrand !== null) {
      coBrandClass = "cobrand"
      coBrand = pressData.coBrand.name
      coBrandOverview = pressData.coBrand.overview.json
    }

    let seoTitle = formattedDate(pressData.publishedDate, true)
    let seoDescription = pressData.title
    let socialImage = headerData.logo

    if (pressData.seo) {
      if (pressData.seo.title) {
        seoTitle = pressData.seo.title
      }
      if (pressData.seo.description) {
        seoDescription = pressData.seo.description.description
      }
      if (pressData.seo.socialImage) {
        socialImage = pressData.seo.socialImage
      }
    }

    return (
      <div className="press-article">
        <Meta
          title={seoTitle}
          description={seoDescription}
          image={socialImage.file.url}
          url={this.props.location.href}
          imgAlt={socialImage.description}
          canonicalUrl={pressData.canonicalUrl ? pressData.canonicalUrl : null}
        />
        <Header />
        <div className="article-intro">
          <h1>{pressData.title}</h1>
        </div>
        <div className="article-content">
          <BodyText text={pressData.body.json} />
          <InquiryWidget
            title={pressData.inquiryWidget.inquiryTitle}
            contactName={pressData.inquiryWidget.contactName}
            email={pressData.inquiryWidget.email}
            socialTitle={pressData.inquiryWidget.socialTitle}
            social={pressData.inquiryWidget.socialMedia}
          />
        </div>
        <BrandOverview
          brand={pressData.brand.name}
          brandOverview={pressData.brand.overview.json}
          coBrandClass={coBrandClass}
          coBrand={coBrand}
          coBrandOverview={coBrandOverview}
        />
        <Footnote footnote={pressData.footnote} />
        <InquiryWidget
          title={pressData.inquiryWidget.inquiryTitle}
          contactName={pressData.inquiryWidget.contactName}
          email={pressData.inquiryWidget.email}
          socialTitle={pressData.inquiryWidget.socialTitle}
          social={pressData.inquiryWidget.socialMedia}
        />
        <Footer />
      </div>
    )
  }
}
