import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "../styles/components/_footnote.scss"

const Footnote = ({ footnote }) => {
  if (footnote !== null) {
    return (
      <div className="footnote">{documentToReactComponents(footnote.json)}</div>
    )
  } else {
    return null
  }
}

export default Footnote
