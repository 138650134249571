import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "../styles/components/_brandOverview.scss"

const BrandOverview = ({
  brand,
  brandOverview,
  coBrandClass,
  coBrand,
  coBrandOverview,
}) => {
  return (
    <ul className="brand-overview">
      <li className="brand">
        <h2>{`About ${brand}`}</h2>
        <div className="overview">
          <div className="overview-text">
            {documentToReactComponents(brandOverview)}
          </div>
        </div>
      </li>
      <li className={coBrandClass}>
        <h2>{`About ${coBrand}`}</h2>
        <div className="overview">
          <div className="overview-text">
            {documentToReactComponents(coBrandOverview)}
          </div>
        </div>
      </li>
    </ul>
  )
}

export default BrandOverview
